<template>
<div class="row" v-if="computedOptions.products[0].isValidationRequired">
        <div class="col">
            Votre réservation doit est validée en fonction des demandes sur cette période. <br/>
            <span v-if="(new Date(computedOptions.products[0].expectedValidationDate)).getTime() > (new Date()).getTime()">La validation de votre réservation est prévue au <b>{{ formatDate(computedOptions.products[0].expectedValidationDate) }}</b></span>
        </div>
    </div>
</template>

<style>

</style>

<script lang="ts">
import { defineComponent, PropType, computed, onMounted, onBeforeUnmount, ref } from '@fwk-node-modules/vue'
import { getApp, useRouter, useStore } from '@fwk-client/utils/vue-3-migration';
import { CmsLabel, CmsText,CmsContent, CmsBoolean, CmsEnum } from '@fwk-client/modules/cms/types';
import { languagesTypes } from '@fwk-client/store/types';
import { formatDay, FormatDateType, FromLocalToUTC, formatDateForInput } from '@igotweb-node-api-utils/formatter';

/** @cmsOptions */
export interface ValidationOptions {
    products:any[]
}

/** @cmsLabels */
export interface ValidationLabels {
}

/** @cmsSlots */
export interface ValidationSlots {
    
}

export default defineComponent({
    props: {
        options: Object as PropType<ValidationOptions>,
        labels: {
          type: Object as PropType<ValidationLabels>,
          default: () => { return {} }
        },
        components: Object as PropType<ValidationSlots>
    },
    components: {
        
    },
    setup(props, context) {
        const app = getApp();
        const $store = useStore();

        const computedOptions:ValidationOptions = {
            products : [],
            ...props.options,
        };

        const labels:ValidationLabels = {
            ...props.labels
        }

        const currentLanguageCode =  $store.getters['languages/' + languagesTypes.getters.GET_CURRENT_LANGUAGE];

        const formatDate = (date:Date) => {
            return formatDay(date, currentLanguageCode, {type: FormatDateType.LONG, isUTC:true});
        }

        return {
            labels,
            computedOptions: {
              ...computedOptions
            },
            formatDate
        }

    }
})

</script>